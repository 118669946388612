<template>
  <b-modal
    size="md"
    id="modal-exchanges"
    :title="$t('terms of use')"
    ok-only
    centered
    hide-footer
    header-class="custom-header"
    no-close-on-backdrop
    modal-bg-variant="custom-opacity"
    @hidden="closeModal"
  >

    <template #modal-header="{ close }">
      <div>
        <h5>{{$t('terms of use')}}</h5>
      </div>
      <b-button size="sm" variant="link" class="text-right p-0" @click="close()">
        X
      </b-button>
    </template>

    <b-overlay :show="isLoadingTermsConditions">
      <div>

        <div class="text-right">
          <b-button
            class="mb-1 btn-modal"
            :disabled="isSavingChoice"
            variant="primary"
            target="_blank"
            rel="noopener noreferrer"
            @click="getTermsAndUse"
          ><feather-icon icon="DownloadIcon" /> {{$t('Download')}}
          </b-button>
        </div>

        <div v-if="!!termCondition" class="content-accept">
          <p class="p-main" v-html="termCondition.content"></p>
        </div>

        <div class="container-buttons mt-1 mb-1" v-if="!exchangesSubscriptionInfo">
          <b-form-checkbox
            class="terms-check"
            v-model="termsStatus"
            :disabled="isSavingChoice"
            :value="true"
            :unchecked-value="false"
          > {{$t('I accept')}} {{ $t('terms of use') }}
          </b-form-checkbox>
        </div>

        <div class="container-buttons" v-if="!exchangesSubscriptionInfo">
          <button class="btn-accepts" type="button" @click="acceptSuscription" :disabled="!termsStatus || isSavingChoice"> {{ $t('accept') }} <b-spinner small v-if="isSavingChoice"/> </button>
        </div>

      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { showAlertMessage } from '@/helpers/helpers'
import { utils } from '@/modules/owners/mixins/utils'

export default {
  mixins: [ utils ],
  props: {
    termCondition: {
      type: Object,
      required: true
    },
    isLoadingTermsConditions: {
      type: Boolean,
      default: false,
      required: false
    }, 
  },
  data(){
    return {
      termsStatus: false,
      imgUrl: process.env.VUE_APP_IMG_SRC_API,
      isSavingChoice: false,
    }
  },
  computed: {
    ...mapState('owners', ['exchangesSubscriptionInfo']),
    ...mapState('auth', ['user','myContracts']),
    ...mapGetters('auth',['contractSelected']),
    langLocale(){
      return this.$i18n.locale
    }
  },
  methods:{
    ...mapActions('owners',['subscribeToExchanges', 'checkIfOwnerIsSubscribedToExchanges']),
    ...mapMutations('owners', ['setExchangesSubscriptionInfo']),
    async closeModal(){
      this.$root.$emit('bv::hide::modal', 'modal-exchanges')
      await this.dataPushAction('declineModalExchanges', 'modalExchanges')
    },
    async getTermsAndUse(){
      // window.print()
      window.open(this.imgUrl +'Catalogs/termsanduse/'+ 24 +'/'+this.user.languageId)
      await this.dataPushAction('downloadFile', 'downloadFile')
    },
    async acceptSuscription(){
      if (!!this.termsStatus) {
        // validar si es admin desde mixin en utils
        if( this.isAdmin ){
          this.$root.$emit('bv::hide::modal', 'modal-exchanges')
          return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`})
        }
        try {
          this.isSavingChoice = true
          const subscribed = await this.subscribeToExchanges({idContract: this.myContracts?.contractSelected?.id, idOwner: this.user.idOwner})
          if (!subscribed?.status) {
            const message = this.$t(subscribed?.message) || this.$t('try again')
            showAlertMessage( '', 'InfoIcon', message, 'warning', 4000, 'bottom-right')
          } else showAlertMessage( this.$t('registration complete'), 'CheckIcon', `${this.$t('welcome to')} ${this.$t('home exchanges')}`, 'success', 4000, 'bottom-right')
          await this.dataPushAction('subscribeToExchanges', 'subscribeToExchanges')

          const response = await this.checkIfOwnerIsSubscribedToExchanges({idContract: this.myContracts?.contractSelected?.id, idOwner: this.user.idOwner})
          this.setExchangesSubscriptionInfo(response?.data || null)
        } catch (error){
          console.warn(error)
          showAlertMessage(this.$t('something failed'), 'InfoIcon', this.$t('Sorry, please try again later'), 'warning', 4000, 'bottom-right')
        }

        this.isSavingChoice = false
        this.$root.$emit('bv::hide::modal', 'modal-exchanges')
      } else {
        return showAlertMessage( '', 'InfoIcon', `${this.$t('you must accept')} ${this.$t('terms of use')}`, 'warning', 4000, 'bottom-right')
      }
    },
    async dataPushAction(action, component){
			await this.pushData('onClick', action, component, this.$route.name ) // push a back del registro
    },
  },
}
</script>

<style>
/* Estilo personalizado para quitar el border-radius del b-modal */
#modal-exchanges .modal-content {
  border: 0.5px solid #6b6a6a;
  border-radius: 0!important;
  /* box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1); */
}

#modal-exchanges .modal-content button.btn-modal{
  border: 0.5px solid #6b6a6a;
  border-radius: 0!important;
}

#modal-exchanges .custom-header{
  display: flex;
  align-items: center;
}

.container-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn-accepts {
  background-color: #333; /* Gris oscuro */
  color: #fff; /* Texto blanco */
  padding: 10px 20px;
  border: 0.5px solid #a1a1a1 !important;
  border-radius: 0px;
  cursor: pointer;
}

.btn-accepts:disabled {
  background-color: #6a6a6a; /* Gris oscuro */
  color: #fff; /* Texto blanco */
  padding: 10px 20px;
  border: 0.5px solid #a1a1a1 !important;
  border-radius: 0px;
  cursor: default;
}

/* Estilos adicionales para pantallas más grandes */
@media (min-width: 768px) {
  .container-buttons {
    flex-direction: row;
    justify-content: space-around;
  }

  .btn-accepts {
    margin: 0.5px;
  }
}

.content-accept {
  text-align: justify;
  color: black;
  overflow-y: auto;
  max-height: 60vh;
}

.p-intro span {
  font-style: italic;
  font-weight: bold;
}

.p-main a{
  font-weight: bold;
}

</style>
